import React, { useContext } from "react"
import ContentResendConfirmLink from "../components/ContentResendConfirmLink"
import { AppContext } from "../contexts/AppContext"
import queryString from "query-string"

/**
 * Here selected country is just used as a weay to trigger client side rendering so that css works well
 */
const ResendConfirmLink = ({ location }) => {
  let { selectedCountry } = useContext(AppContext)
  const queryParams =
    location && location.search ? queryString.parse(location.search) : {}

  if (!queryParams.email)
    return (
      <div>
        <span>No such page</span>{" "}
      </div>
    )
  return <>{selectedCountry && <ContentResendConfirmLink email={queryParams.email} />}</>
}

export default ResendConfirmLink
 